import React, { PureComponent, useState, useCallback, useEffect } from "react";
import CountDownBlock from "./CountDownBlock";
import Link from "../components/LinkWithTransitionLock"
import Helmet from "react-helmet"
import InViewObserver from "../components/InViewObserver"
import PageContentHeadSwitch from "../components/PageCententHeadSwitch"
import { Expo, gsap } from 'gsap';

class Page extends PureComponent {
  constructor(props) {
     super(props);
  }
  
  componentDidMount() {

    InViewObserver()
    
    // var info_cover = document.querySelector('#info_cover');
    
    // gsap.fromTo(info_cover, {
    //   opacity: 0,
    //   scale: '2',
    // }, {
    //   opacity: 1,
    //   scale: '1',
    //   duration: 2,
    //   delay: .5,
    //   ease: Expo.easeOut
    // });
    

  }

  componentWillUnmount() {}

  render() {

    var next_visual = require('../images/home/project_a.png');

    return (
    // <>
    //   <Helmet
    //     title="養一隻植物和你說說話 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫"
    //     meta={[
    //       {
    //         name: `description`,
    //         content: "養一隻植物和你說說話",
    //       },
    //       {
    //         property: `og:title`,
    //         content: "養一隻植物和你說說話 / HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫",
    //       },
    //       {
    //         property: `og:description`,
    //         content: "養一隻植物和你說說話",
    //       }
    //     ]}
    //   >
    //   </Helmet>

      <div className="content_wrap">

        <div className="page_header">
          <h2 className="sub_title">策展人/賴映如
            <PageContentHeadSwitch/>
          </h2>
          <h1 className="main_title">蛤？<br />養植物也是公共藝術嗎？</h1>
        </div>

        <div className="page_content project_d_content">

          <section className="row_area_s project_d_a">
            <div className="column_6">
              <div className="context">
                <p>現代人的生活往往被工作填滿。在冷硬的鋼筋混凝土建築，和一條又一條灰色的巷道間移動，誰能記得上次與自然親近的時刻？難道我們與自然的關係，只能夠如此嗎？</p>
                <p>植物是安靜而中性的，不論來自何方，只要談論到植物，或者在社區內種菜，大家往往能卸下心防，和平共處。因為種植本身並沒有利害關係。懂得傾聽植物話語的人，內心始終溫柔。倘若我們將社宅視為複雜的大容器，那植物或許會是極佳的介質，滴入之後，淨化並改變容器內的成份。</p>
                <p className="onset_right_2"><strong>重新讓植物回到生活中吧！養一株盆栽，和他說話，傾聽他的快樂與苦惱。你會發現，植物能教我們的東西其實很多。</strong></p>
                <p className="onset_right_2">抱持這樣的想法，這場展覽以實境秀的形式登場。策展人會待在精心佈置的美麗花園中，依循「土壤」、「植物心情」等不同主題，每次邀請一位來賓互動，針對主題分享想法，也彼此學習。過程以影片記錄，成為線上可觀賞的對談。同時，搭配一小段展現花園光影變幻的短片。網上收看時，亦可閱讀策展人親筆書寫關於植物的小語錄。運用溫馨幽默的方式，提醒著有了植物的生活，平衡而愉悅。植物能讓我們，讓整個社群都更健康。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_a_2 row_gallery">
            <div className="column_5 onset_left_1">
              <div className="context">
                <h3>小Ｑ語錄</h3>
                <p>擔任此次計畫的策展人賴映如，因為一頭捲髮被暱稱為小Ｑ，她平時講話超有梗，張口便出 slogan，因此特別為她企劃了小Ｑ語錄，每次點進頁面都會有一句隨機的語錄，透過植物光影變化的短片搭配上她俏皮的語調，就像植物的生命力一樣，希望能在日常生活中，給大家帶來療癒和歡樂。</p>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_b_1 enter_cover">
            <div className="column_8 enter_cover_container">
              <div className="context">
                <h6>Hi, 歡迎來到「小Ｑ語錄」</h6>
                <p>進場前，<strong>建議您調整適當音量或佩戴耳機</strong>，方能感受完整的作品體驗。當你準備好，即可進入小Ｑ的日常生活中。</p>
                <Link to="/plants/" className="enter_cover_btn">
                  <div className="btn_inner">請進場</div>
                </Link>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_b row_gallery">
            <div className="column_6">
              <div className="context">
                <h3>小Ｑ實境秀</h3>
                <p>每日更新</p>
                <p className="tag">影像創意/黑鳥音像 BlackBird Imagine Studio</p>
                <div className="video">
                  <iframe width="560" height="315" src="https://www.youtube.com/embed/PvzjkF01U94" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                  <h5 className="video_title">EP1 植物寵物系</h5>
                </div>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_c row_gallery">
            <div className="column_6 onset_left_2">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/Om5tghevZu0" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">EP2 植物的 Body language</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_d row_gallery">
            <div className="column_6">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/yjECsWOHDVY" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">EP3 幫植物作環保新家</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_e row_gallery">
            <div className="column_6 onset_left_2">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/KsIjyWRGlLQ" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">EP4 植物魔法場域</h5>
              </div>
            </div>
          </section>

          <section className="row_area_s project_d_e row_gallery">
            <div className="column_6 onset_left_1">
              <div className="video">
                <iframe width="560" height="315" src="https://www.youtube.com/embed/uG9Y7Lje-5w" frameBorder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowFullScreen></iframe>
                <h5 className="video_title">EP5 養一隻植物和你說說話</h5>
              </div>
            </div>
          </section>


        </div>


        <div className="head_intro_content headroom">
          <h5 className="name">賴映如</h5>
          <h6 className="title">景觀設計師 野再設計創辦人</h6>
          <p className="bio">景觀設計師，在景觀界裡十餘年來，與植物建立起密切的關係，總是從不發一語的植物身上獲得很多訊息，2018年成立野再設計。在集合住宅、商辦大樓、公園綠地等等不同尺度的開放空間裡，實驗各種公眾交流的可能性，藉由設計傳遞自然生態的訊息，創造人與環境互動對話的機會。</p>
        </div>

        <div className="page_footer">
          <Link to="/project_a" className="next_page">
            <div className="next_title">成為我的繆思，獻給他的禮物</div>
            <div className="next_owner">策展人/沈伯丞</div>
            <div className="next_visual" style={ { backgroundImage: "url(" + next_visual + ")" } }></div>
          </Link>
        </div>

      </div>

    // </>
    
      // <CountDownBlock blockType="comingSoon" targetDate={new Date(2020, 4, 27)}>
      //   <section className="size_s">
      //     <h4>《蛤？養植物也是公共藝術嗎？》</h4>
      //   </section>
      //   <h5>不要叫我植物醫生，我們不能醫治植物，是植物醫治我們</h5>
      //   <h6>賴映如/景觀設計師 野再設計創辦人</h6>
      //   <p>將於 5/4 上線</p>
      // </CountDownBlock>
    );
  }

}


export default Page
