import React, { PureComponent, useState, useCallback, useEffect } from "react";
import Helmet from "react-helmet"
import Page from "../components/project_d.js"
import IndexSPA from "../components/IndexSPA"
import { Children } from "react";

// export default Page

const Index = (props) => {
   return (
      <>
        <Helmet
          title="台北萬華 最美的是人 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽"
          meta={[
            {
              name: `description`,
              content: "閱讀了林立青的著作：做工的人，他的筆讓我們的眼光落向了這城市被遺忘、忽略的角落和人生。也正是立青的文章讓我產生了共鳴，公共（public）應該是公眾的記憶集合體，而公共藝術應該是",
            },
            {
              property: `og:title`,
              content: "台北萬華 最美的是人 / HOME TO ALL 我們也可以這樣生活 / 臺北社宅藝術計劃線上展覽",
            },
            {
              property: `og:description`,
              content: "閱讀了林立青的著作：做工的人，他的筆讓我們的眼光落向了這城市被遺忘、忽略的角落和人生。也正是立青的文章讓我產生了共鳴，公共（public）應該是公眾的記憶集合體，而公共藝術應該是",
            }
          ]}
        >
        </Helmet>
         <IndexSPA />
         {/* <Page /> */}
      </>
   )
 };
 
 // Index.propTypes = {}
 
 export default Index;