import React, { useRef, useState, useCallback, useEffect } from "react";
import PropTypes from "prop-types";
import useCountdown from "react-use-countdown";
import parseMs from "parse-ms";

import sty from "./CountDownBlock.module.scss";

const CountDownCover = ({ children, days, hours, minutes, seconds }) => {
  return (
    <div className={sty.CountDownCover} id="info_cover">
      {children}
      <p className="time">
        {days * 24 + hours + "：" + minutes + "：" + seconds}
      </p>
    </div>
  );
};

const ComingSoonCover = ({ children }) => {
  return (
    <div className={sty.ComingSoonCover} id="info_cover">
      {children}
      {/* <p><a href="https://www.facebook.com/HOME-to-ALL-2866082383405643/">立即追蹤我們粉絲團</a><br />接收最新動態！</p> */}
    </div>
  );
};

const CountDownBlock = ({ children, targetDate, blockType, Component }) => {
  const countdown = useCountdown(() => targetDate);
  const parseTime = parseMs(countdown);

  return targetDate > new Date() ? (
    blockType === "countDown" ? (
      <CountDownCover {...parseTime}> {children} </CountDownCover>
    ) : (
      <ComingSoonCover> {children} </ComingSoonCover>
    )
  ) : (
    <Component />
  );
};

CountDownBlock.propTypes = {
  blockType: PropTypes.oneOf(["countDown", "comingSoon"]).isRequired,
  targetDate: PropTypes.object.isRequired,
  children: PropTypes.object,
  Component: PropTypes.func,
};

export default CountDownBlock;
