
import React, { Suspense, lazy, useState, useCallback, useEffect } from "react"
import { Router, Link } from "@reach/router"

import Headroom from "headroom.js"
import { isMobile } from "react-device-detect"
import window from 'global'

class Page extends React.Component {

  constructor(props) {
    super(props);
  }
  
  componentDidMount () {

   var head_intro_switch = document.querySelector('.head_intro_switch');
   var head_intro_content = document.querySelector('.head_intro_content');
   var page_content = document.querySelector('.page_content');
   if (isMobile){
     var scroller_wrap = document.querySelector('#wrap');
   } else {
     var scroller_wrap = window;
   }
   var options = {
     scroller : scroller_wrap,
     onNotTop : function() {
       head_intro_switch.classList.remove('intro_in');
       head_intro_content.classList.remove('intro_in');
       page_content.classList.remove('intro_in');
     }
   };
   var headroom  = new Headroom(head_intro_content, options);

   headroom.init();

   head_intro_switch.addEventListener('click', function(){
     this.classList.toggle('intro_in');
     head_intro_content.classList.toggle('intro_in');
     page_content.classList.toggle('intro_in');
   });
      
  }
 
  componentWillUnmount() {

  }


  render() {

      return (
         <div className="head_intro_switch">
            <svg width="22" height="22" viewBox="0 0 22 22">
               <g fillRule="nonzero" fill="none">
                  <path
                  d="M15.027 10.3H11.7V6.973a.176.176 0 00-.175-.175h-1.05a.176.176 0 00-.175.175V10.3H6.973a.176.176 0 00-.175.175v1.05c0 .097.079.175.175.175H10.3v3.327c0 .096.078.175.175.175h1.05a.176.176 0 00.175-.175V11.7h3.327a.176.176 0 00.175-.175v-1.05a.176.176 0 00-.175-.175z"
                  fill="#000"
                  />
                  <path
                  d="M11 1.196c-5.414 0-9.804 4.39-9.804 9.804 0 5.414 4.39 9.804 9.804 9.804 5.414 0 9.804-4.39 9.804-9.804 0-5.414-4.39-9.804-9.804-9.804z"
                  stroke="#000"
                  strokeWidth="2"
                  />
               </g>
            </svg>
         </div>
      )

   }

}


export default Page

  